import React from "react"
import chocolatHero from '../images/chocolatHero.png';
import Layout from "../components/layout"
import Product from "../components/product/product"
import SEO from "../components/seo"
import buch from "../images/products/bread/bucheron.jpg";

const products =[
  {
    name:"Pain Bûcheron",
    description : "Pain spécial, pimenté avec du poil de dahû, à base de farine et d'eau (oui oui, il y a de la farine)",
    img : buch
  },
  {
    name:"Pain Bûcheron",
    description : "Pain spécial, pimenté avec du poil de dahû, à base de farine et d'eau (oui oui, il y a de la farine)",
    img : buch
  },
  {
    name:"Pain Bûcheron",
    description : "Pain spécial, pimenté avec du poil de dahû, à base de farine et d'eau (oui oui, il y a de la farine)",
    img : buch
  },
  {
    name:"Pain Bûcheron",
    description : "Pain spécial, pimenté avec du poil de dahû, à base de farine et d'eau (oui oui, il y a de la farine)",
    img : buch
  },
  {
    name:"Pain Bûcheron",
    description : "Pain spécial, pimenté avec du poil de dahû, à base de farine et d'eau (oui oui, il y a de la farine)",
    img : buch
  },
  {
    name:"Pain Bûcheron",
    description : "Pain spécial, pimenté avec du poil de dahû, à base de farine et d'eau (oui oui, il y a de la farine)",
    img : buch
  },
]

const IndexPage = () => (
  <Layout>
    <SEO title="Pain" />
    <div className="products">
      <section>
        <div className="fluid-container">
          <div className="row">
            <img alt="Chocolat" src={chocolatHero} style={{width:"100%"}}/>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <h1 className="col-md-12 times" style={{textAlign:"center",margin:"62px 0px"}}>Nos chocolats</h1>
          </div>
          <div className="row">
            <div className="col-md-12" style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
            }}>
            {
              products.map((product)=><Product name={product.name} description={product.description} img={product.img} />)
            }
            </div>
          </div>
        </div>
      </section>

    </div>
  </Layout>
)

export default IndexPage
